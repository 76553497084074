import "./App.css";

import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import {
  Button,
  Container,
  Input,
  Form,
} from "semantic-ui-react";
import SearchBox from "./SearchBox";
import axios from "axios";

export default function Account(props) {
  window.analytics.page("Account", {
    url: "https://earnings.io/account"
  });

  function signOut() {
    props.setUserID(null);
    localStorage.clear();
  }
  console.log("Sending ", props.userID)
  return (
    <Container>
      <form method="GET" action="https://earnings.io/api/portal">
        <input type="hidden" name="userID" value={props.userID} />
        <Button type="submit">Manage billing</Button>
      </form>
    </Container>
  );
}
