import "./SearchBox.css";

import { Button, Icon, Label, Search, Segment } from "semantic-ui-react";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

export default function SearchBox(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchFetching, setSearchFetching] = useState(false);
  const [buttonFetching, setButtonFetching] = useState(false);
  const [labels, setLabels] = useState([]);

  async function searchStocks(e) {
    setSearchQuery(e.target.value);
    setSearchFetching(true);
    try {
      const query = e.target.value;
      const res = await axios(
        "https://earnings.io/api/search?query=" + query
      );
      window.analytics.track('Search results', { query: query });
      setSearchFetching(false);
      // filtering out stocks that have already been picked
      const filteredResults = res.data.results.filter(
        (r) => !labels.find((l) => r.ticker === l.key)
      );
      setSearchResults(
        filteredResults.map((r) => ({
          title: r.ticker,
          id: r.ticker,
          description: r.name,
        }))
      );
    } catch (err) {
      window.analytics.track('Error on search results', { error: err });
      console.log(`Error on searching for results: \n${err}`);
    }
    setSearchFetching(false);
  }

  function addLabel(e, data) {
    labels.push(
      <Label
        key={data.result.title}
        content={data.result.title}
        removeIcon="delete"
        onRemove={removeLabel}
      />
    );
    // have to spread to a new array so it triggers a react re-render
    setLabels([...labels]);
    setSearchQuery("");
  }

  function removeLabel(e, data) {
    console.log(`Removing: ${data.content}`);
    setLabels(
      labels.filter((l) => {
        return data.content !== l.key;
      })
    );
  }

  function generateButtonClick(e, data) {
    const url =
      "https://earnings.io/podcast?stock=" +
      labels.map((l) => l.key).join(",") + "&id=" + props.userID;
    props.setUrl(url);
    props.setIconLoading(true);
    setLabels([])
    setTimeout(() => props.setIconLoading(false), 1000)
    window.analytics.track('Podcast generation click', { stocks: labels.map((l) => l.key).join(",") });
  }

  return (
    <Segment.Group className={props.searchSegmentBorder ? "searchBoxContainer" : null}>
      <Segment>
        <div>{labels}</div>
      </Segment>
      <Segment>
        <Search
          loading={searchFetching}
          onResultSelect={addLabel}
          onSearchChange={searchStocks}
          results={searchResults}
          value={searchQuery}
          placeholder="Search companies"
        />
      </Segment>
      <Segment>
        <Link to="/podcasts">
          <Button
            icon
            labelPosition="right"
            loading={buttonFetching}
            onClick={generateButtonClick}
          >
            Generate Podcast
            <Icon name="right arrow" />
          </Button>
        </Link>
      </Segment>
    </Segment.Group>
  );
}
