import "./Header.css";

import logo from './earnings-logo.png';

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Segment,
  Menu,
  Input,
  Grid
} from "semantic-ui-react";

export default function PageHeader(props) {
  const [activeItem, setActiveItem] = useState("");

  var handleItemClick = function (e, { name }) {
    setActiveItem(name)
  }

  function signOut() {
    props.setUserID(null);
    localStorage.clear();
  }
  function loggedInMenu() {
    return (
      <Menu secondary className="header-menu loggedin-menu">
        <Link to="/podcasts">
          <Menu.Item
            name='Podcasts'
            active={activeItem === 'Podcasts'}
            onClick={handleItemClick}
          />
        </Link>
        <Link to="/about">
          <Menu.Item
            name='About'
            active={activeItem === 'About'}
            onClick={handleItemClick}
          />
        </Link>
        <Link to="/account">
          <Menu.Item
            name='Account'
            active={activeItem === 'Account'}
            onClick={handleItemClick}
          />
        </Link>
        <Link to="/login">
          <Menu.Item
            name='Logout'
            active={activeItem === 'Logout'}
            onClick={signOut}
          />
        </Link>
      </Menu>
    )
  }
  function loggedOutMenu() {
    return (
      <Menu secondary className="header-menu loggedout-menu">
        <Link to="/about">
          <Menu.Item
            name='About'
            active={activeItem === 'About'}
            onClick={handleItemClick}
          />
        </Link>
        <Link to="/register">
          <Menu.Item
            name='Register'
            active={activeItem === 'Register'}
            onClick={handleItemClick}
          />
        </Link>
        <Link to="/login">
          <Menu.Item
            name='Login'
            active={activeItem === 'Login'}
            onClick={handleItemClick}
          />
        </Link>
      </Menu>
    )
  }

  return (
    <Container className="header-container">
      <Grid stackable centered textAlign="center">
        <Grid.Column className="header-column" floated='left' width={5} as={Link} to="/">
          <div className="logo">
            <div className="logo-image-wrapper"><img className="logo-image" src={logo} alt="Earnings Logo" /></div>
            <div className="logo-text">Custom podcasts for your portfolio</div>
          </div>
        </Grid.Column>
        <Grid.Column className="header-column" floated='right' width={5}>
          {props.userID ? loggedInMenu() : loggedOutMenu()}
        </Grid.Column>
      </Grid>
    </Container>
  );
}
