import { List, Card, Container, Header, Grid } from "semantic-ui-react";
import "./Home.css";

import React, { useState } from "react";

import SearchBox from "./SearchBox";
import axios from "axios";

export default function Home(props) {
  window.analytics.page("Home", {
    url: "https://earnings.io"
  });
  console.log("in props");
  console.log(props);
  const [messageCopied, setMessageCopied] = useState(false);
  return (
    <Container>
      <Grid className="description" columns={2} stackable centered>
        <Card.Group centered>
          <Card
            className="callout-card"
            header={
              <Header className="callout-text"
                size='small'>Turn any earnings call into a podcast! </Header>
            }
            description={
              <List className="callout-list" bulleted>
                <List.Item>Your podcast can contain just one or multiple stocks in one podcast</List.Item>
                <List.Item>Your podcast will contain historical earnings calls and new earnings calls are added as new episodes the moment they're released</List.Item>
                <List.Item>Create unlimited custom podcasts</List.Item>
              </List>
            }
          />
          <Card
            className="callout-card"
            header={
              <Header className="callout-text"
                size='small'>Why listen to earnings calls? </Header>
            }
            description={
              <List className="callout-list" bulleted>
                <List.Item>Public companies and their executives talk about their companies four times a year. They're a great resource to learn about these companies, understand how executives think, and learn how to run and build a business </List.Item>
                <List.Item>Whether you're an investor, entrepreneur, employee, or just like learning about businesses they're an incredible resource to learn. </List.Item>
              </List>
            }
          />
        </Card.Group>
      </Grid>

      <SearchBox {...props} />
    </Container>
  );
}
