import "./Login.css";

import {
  Button,
  Container,
  Icon,
  Input,
  Form,
  Message,
} from "semantic-ui-react";
import React, { useState } from "react";

import { Redirect } from "react-router-dom";
import axios from "axios";

export default function Login(props) {
  window.analytics.page("Login", {
    url: "https://earnings.io/login"
  });
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(false)
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function login() {
    setLoading(true);
    // First register the user, get a user id and a stripe consume key
    let res;
    try {
      res = await axios({
        method: "POST",
        url: "https://earnings.io/api/login",
        data: {
          email: email,
          password: password,
        },
      });
    } catch (err) {
      console.log("Error logging in");
      window.analytics.track('Error logging in', {
        error_message: err.response
      });
      console.log(err.response)
      setLoading(false);
      setLoginError(true);
      return;
    }
    window.analytics.track('Success logging in');
    const userID = res.data.userID;
    props.setUserID(res.data.userID);
    setLoading(false);
  }
  if (props.userID) {
    return <Redirect to="/podcasts" />;
  } else {
    return (
      <Container className="login-container">
        <Form error={loginError} className="login-form">
          <Message
            error
            header='Invalid login'
            size="tiny"
            className="login-error-message"
            content='Your username or password was incorrect'
          />
          <Form.Field>
            <Input
              focus
              placeholder={emailError ? "Email already used" : "Email"}
              value={email}
              error={emailError}
              type="email"
              className="login-input"
              onChange={(e) => {
                setEmailError(false);
                setEmail(e.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Input
              focus
              placeholder="Password"
              value={password}
              type="password"
              className="login-input"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={e => { if (e.key === "Enter") login() }}
            />
          </Form.Field>
        </Form>
        <Button
          icon
          labelPosition="right"
          type="submit"
          loading={loading}
          onClick={login}
        >
          {"Login"}
          <Icon name="right arrow" />
        </Button>
      </Container>
    );
  }
}
