import "./App.css";

import React, { useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import About from "./About";
import Header from "./Header";
import Home from "./Home";
import Account from "./Account";
import { Elements } from "@stripe/react-stripe-js";
// import Home from "./Home";
import Login from "./Login";
import Podcasts from "./Podcasts";
import Register from "./Register";
import SearchBox from "./SearchBox";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  const [earningsUrl, setEarningsUrl] = useState("");
  const [userID, setUserIDReact] = useState("");

  const stripePromise = loadStripe("pk_live_g6dllVkP2ZFfc4s7xcjgH6l600RA7fB1vI");

  if (!userID) {
    // Setting and reading from localstorage
    let localUserID = localStorage.getItem("userID");
    if (localUserID) setUserIDReact(localUserID);
  }

  function setUserID(v) {
    setUserIDReact(v);
    localStorage.setItem("userID", v);
  }

  return (
    <div className="App">
      <Router>
        <Header userID={userID} setUserID={setUserID} />
        <Switch>
          <Route exact path="/">
            <Home setUrl={setEarningsUrl} earningsUrl={earningsUrl} />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route path="/register">
            <Elements stripe={stripePromise}>
              <Register
                earningsUrl={earningsUrl}
                userID={userID}
                setUserID={setUserID}
              />
            </Elements>
          </Route>
          <Route path="/login">
            {userID ? <Redirect to="/podcasts" /> : <Login setUserID={setUserID} />}
          </Route>
          <Route path="/account">
            {userID ? (
              <Account setUserID={setUserID} userID={userID} />
            ) : (
                <Redirect to="/login" />
              )}
          </Route>
          <Route path="/podcasts">
            {userID ? (
              <Podcasts
                earningsUrl={earningsUrl}
                setUrl={setEarningsUrl}
                userID={userID}
              />
            ) : (
                <Redirect to="/register" />
              )}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
