import "./Register.css";

import {
  Button,
  Card,
  Container,
  Grid,
  Icon,
  Input,
  Radio,
  Segment,
  Checkbox,
  Message
} from "semantic-ui-react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";

import { Redirect } from "react-router-dom";
import axios from "axios";

export default function Register(props) {
  window.analytics.page("Register", {
    url: "https://earnings.io/register"
  });
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [errorBody, setErrorBody] = useState("Unknown error")
  const [errorHeader, setErrorHeader] = useState("Unknown error")
  const [hideError, setHideError] = useState(true)
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [hasCode, setHasCode] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [freeSelected, setFreeSelected] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState(true);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(true);

  const CARD_ELEMENT_OPTIONS = {
    options: {
      fonts: [
        {
          src: 'https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2',
          family: 'Lato',
          style: 'normal',
        }
      ]
    },
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Lato, Helvetica Neue, Arial, Helvetica, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "1.07em",
        height: "55px",
        "::placeholder": {
          color: "rgba(0, 0, 0, 0.4)",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  async function register() {
    setLoading(true);
    // Make sure Stripe is loaded
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    let pm
    if (!code) {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        setErrorHeader("Card error")
        setErrorBody("An error occured while inputting your credit card")
        setHideError(false)
        console.log('[error]', error);
      } else {
        pm = paymentMethod
      }
    } else {
      window.analytics.track('Code selected', {
        code: code
      });
    }
    let res;
    try {
      if (!email || !password) {
        setHideError(false)
        setErrorHeader("Invalid registration")
        setErrorBody("Email and Password required")
        setLoading(false)
        return;
      }
      if (!pm && !code) {
        setHideError(false)
        setErrorHeader("Invalid registration")
        setErrorBody("Credit card or code required")
        setLoading(false)
        return;
      }
      window.analytics.track('Registration attempted', {
        freeTrial: freeSelected,
        subscription: subscriptionSelected,
        monthly: monthSelected,
        year: yearSelected
      });
      res = await axios({
        method: "POST",
        url: "https://earnings.io/api/register",
        data: {
          email: email,
          password: password,
          paymentMethodID: pm ? pm.id : null,
          code: code,
          freeTrial: freeSelected,
          monthSelected: monthSelected,
          yearSelected: yearSelected
        },
      });
    } catch (err) {
      if (!err.response) {
        window.analytics.track('Error on registration');
        setHideError(false)
      } else if (
        err.response.status === 400 &&
        err.response.data.error_message === "Email already exists"
      ) {
        window.analytics.track('Error on registration', { error_message: "email already used" });
        setEmailError(true);
        setErrorHeader("Registration Error")
        setErrorBody("This email is already in use.")
        setHideError(false)
        setEmail("");
      } else if (err.response.data.error_header) {
        window.analytics.track('Error on registration', { error_message: err.response.data.error_message });
        console.log(err);
        setHideError(false)
        setErrorHeader(err.response.data.error_header)
        setErrorBody(err.response.data.error_message)
      } else {
        window.analytics.track('Error on registration', { error_message: err.response.data.error_message });
        console.log(err);
        setHideError(false)
        setErrorBody(err.response.data.error_message)
      }
      setLoading(false);
      return;
    }
    window.analytics.track('Registration succesful');
    window.analytics.identify(res.data.userID, {
      email: email,
      $email: email,
      $name: email,
    });
    props.setUserID(res.data.userID);
    setLoading(false);
  }
  if (props.userID) {
    return <Redirect to="/podcasts" />;
  } else {
    return (
      <Container>
        <Segment.Group>
          <Segment>
            <Grid columns={2} stackable centered>
              <Card.Group centered>
                <Card
                  link
                  className={freeSelected ? "card-selected" : ""}
                  onClick={() => {
                    setFreeSelected(true);
                    setSubscriptionSelected(false);
                  }}
                >
                  <Card.Content>
                    <Card.Header>Free Trial</Card.Header>
                    <Card.Description>
                      <ul>
                        <li>Create unlimited podcasts for a month </li>
                        <li>Ability to cancel at any time</li>
                      </ul>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Radio checked={freeSelected} label="Free for one month" />
                  </Card.Content>
                </Card>
                <Card
                  link
                  className={subscriptionSelected ? "card-selected" : ""}
                  onClick={() => {
                    setFreeSelected(false);
                    setSubscriptionSelected(true);
                  }}
                >
                  <Card.Content>
                    <Card.Header>Subscription</Card.Header>
                    <Card.Description>
                      <ul>
                        <li>Create unlimited earnings podcasts </li>
                      </ul>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    {" "}
                    <Radio
                      checked={yearSelected}
                      label="$100/year"
                      onClick={() => {
                        setYearSelected(true);
                        setMonthSelected(false);
                        setFreeSelected(false);
                      }}
                    />
                    <Radio
                      checked={monthSelected}
                      onClick={() => {
                        setYearSelected(false);
                        setMonthSelected(true);
                        setFreeSelected(false);
                      }}
                      label="$10/month"
                    />
                  </Card.Content>
                </Card>
              </Card.Group>
            </Grid>
          </Segment>
          <Segment.Group className="register-form">
            <Segment>
              <Message
                error
                header={errorHeader}
                size="tiny"
                className="login-error-message"
                content={errorBody}
                hidden={hideError}
              />
            </Segment>
            <Segment>
              <Input
                focus
                centered
                placeholder={emailError ? "Email already used" : "Email"}
                value={email}
                error={emailError}
                className="registerInput"
                type="email"
                onChange={(e) => {
                  setEmailError(false);
                  setEmail(e.target.value);
                }}
              />
            </Segment>
            <Segment>
              <Input
                focus
                placeholder="Password"
                value={password}
                className="registerInput"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Segment>
            <Segment>
              {hasCode ? <Input
                focus
                centered
                placeholder={codeError ? "Invalid code" : "Code"}
                value={code}
                error={codeError}
                className="registerInput"
                onChange={(e) => {
                  setCodeError(false);
                  setCode(e.target.value);
                }}
              /> : <CardElement options={CARD_ELEMENT_OPTIONS} />}
            </Segment>
            <Segment>
              <Checkbox
                label='I have a code'
                onChange={() => setHasCode(!hasCode)}
              // checked={this.state.checked}
              />
            </Segment>
            <Segment>
              <Button
                icon
                labelPosition="right"
                loading={loading}
                onClick={register}
              >
                {freeSelected ? "Register" : "Register & Pay"}
                <Icon name="right arrow" />
              </Button>
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Container>
    );
  }
}
