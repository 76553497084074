import { List, Card, Container, Header, Grid } from "semantic-ui-react";
import "./About.css";

import React, { useState } from "react";

export default function About(props) {
    window.analytics.page("About", {
        url: "https://earnings.io/about"
    });
    return (
        <Container>
            <div className="about">
                <br />
                <br />
                As founders we've always loved listening to earnings reports. They give you a really interesting window into companies and how they operate. Sometimes they're filled with too much corporate speak - but even then you get to learn how these leaders talk, describe the environment and what matters to them and the company.
                <br />
                <br />
                Some people think that earnings reports are only valuable to investors or bankers - but that couldn't be further from the truth. Whether you're a founder trying to get up to speed on a new market, an employee trying to find information on a competitor, someone wanting speaking tips for their next all hands, or just a curious learner, earnings reports are an invaluable resource.
                <br />
                <br />
                However, the format is fundamentally broken. Unless you work at a big bank or fund you probably don't have access to something like Bloomberg to make these easily available. Instead you have to dig into some corporate investor relations website to listen online - and then you usually have to hand over personal information and deal with some clunky 90's era desktop site. We believe podcasts are the perfect listening format. Being device and app agnostic you can listen to them while you're running, commuting or cooking at home. Earnings reports can now fit into your existing workflow!
                <br />
                <br />
                This isn't a money making project. I charge to cover my AWS bill and the associated costs of the earnings reports. If the $10/month or $100/year is cost prohibitive shoot me a note and I'm sure we can figure something out!
                <br />
                <br />
                For questions and comments shoot me an email at <a href="mailto:earnings@williamhockey.com">earnings@williamhockey.com</a>
                <br />
                <br />
                This is a project by <a target="_blank" href="https://www.williamhockey.com/">William Hockey</a> with some solid help from <a target="_blank" href="https://twitter.com/dwnoam"> David Noam</a>. But the idea was blatantly stolen from <a target="_blank" href="https://twitter.com/zachperret">Zach Perret</a>.
                <br />
                <br />
            </div>
        </Container>
    );
}
