import "./Podcasts.css";

import { Container, Input, Button, Icon } from "semantic-ui-react";
import React, { useState } from "react";

import SearchBox from "./SearchBox";
import axios from "axios";

export default function Podcast(props) {
  window.analytics.page("Podcast", {
    url: "https://earnings.io/podcasts"
  });
  console.log("in props");
  console.log(props);
  const [messageCopied, setMessageCopied] = useState(false);
  const [messageHidden, setMessageHidden] = useState(false);
  const [iconLoading, setIconLoading] = useState(false)
  const [otherApps, setOtherApps] = useState(false)
  const iOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);


  function iOSDirectLink() {
    return (
      <div className="iOSDirectLink">
        {otherApps ? otherDirectLink() :
          <div style={{textAlign: "center"}}>
            <a 
              onClick={ () => {
                window.analytics.track('Clicked direct link', {url: props.earningsUrl});
                return true;
              }}
               href={"podcast://" + props.earningsUrl.replace('https://', '')}>Open directly in Apple Podcasts</a> <br/><br/>
            <a onClick={() => {
              window.analytics.track('Clicked other apps link', {url: props.earningsUrl});
              setOtherApps(true)  
            }}>Other applications</a>
          </div>
        }
      </div>
    )
  }

  function otherDirectLink() {
    return (
      <div className="otherDirectLink">
              Because it's a custom URL you'll have to add it directly to your podcast app. < a target = "_blank" href = "https://transistor.fm/add-podcast" > See instructions on how to add it to your podcast app.</a > <br /> <br /> If you're using Apple Podcasts you can  <a href={"podcast://" + props.earningsUrl.replace('https://', '')}> add it directly here. </a>
      </div>
    )
  }

  return (
    <Container className="podcastContainer">
      {props.earningsUrl ? (
        <div>
          <div className="readyHeader"><Icon loading={iconLoading} color="olive" name={iconLoading ? 'spinner' : 'check'} /> Your podcast feed is ready!</div>
          <Input
            action={{
              className: "urlButton",
              color: "blue",
              labelPosition: "right",
              icon: messageCopied ? "check" : "copy",
              content: messageCopied ? "Copied!" : "Copy",
              onClick: (e) => {
                /* Get the text field */
                const urlElement = document
                  .getElementsByClassName("urlText")[0]
                  .getElementsByTagName("input")[0];

                urlElement.select();
                urlElement.setSelectionRange(0, 99999); /*For mobile devices*/
                document.execCommand("copy");
                setMessageCopied(true);
                window.analytics.track('Copied url', {url: urlElement});
                setTimeout(() => setMessageCopied(false), 2000);
              },
            }}
            className="urlText"
            value={props.earningsUrl}
          />
          <div className="urlTextMessage">
            <div className="directLink">
              {iOS ? iOSDirectLink() : otherDirectLink()}
            </div>
          </div>
        </div>
      ) : null}

      <SearchBox {...{ searchSegmentBorder: true, setIconLoading: setIconLoading }} {...props} />
    </Container>
  );
}
